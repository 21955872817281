





































































































































































































































































































  @import '~@/assets/styles/view/usuarios-web';
  .opem-modal-info {
    cursor: pointer;
  }
.filtro-range button {
  min-height: 40px !important;
}
